import { breakpoints, theme } from "@shapeable/theme";
import { WARM_GREY } from "@shapeable/ui";
import { css } from "styled-components";

export const SCOPE_ORANGE = '#F87121';
// export const YELLOW_DARK = '#FCB10F';
// export const YELLOW_ORANGE = '#F79616';

export const themeOverrides = {
  light: {
    color: {

      darkBackground: '#202124',
      lightBackground: '#F8F8F8',
  
      midBrand: SCOPE_ORANGE,
      primary: SCOPE_ORANGE,
  
  
      linkHover: SCOPE_ORANGE,
      link: SCOPE_ORANGE,

      linkInvertedHover: '#000',
      linkActive: SCOPE_ORANGE,
  
      menuItemLinkHover: SCOPE_ORANGE, 
      menuItemLinkActive: SCOPE_ORANGE, 
      invertedMenuItemLinkHover: SCOPE_ORANGE, 
  
    },
  },
  font: {
    family: {
      serif: "Cormorant, georgia, serif",
      sans: 'Readex Pro, sans-serif',
      heading: 'Readex Pro, sans-serif',
      copy: 'Inter, sans-serif',
    }
  },
};


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`

    .page-home .shp--page-layout-standard__banner-titles div div {
      flex-direction: column;
      gap: 0.25em;

      h1 {
        align-self: flex-start;
      }
    }

    .shp--slice-layout-intro {
      

      strong {
        color: ${theme.COLOR(SCOPE_ORANGE)};
      }
    }

    .shp--site-menu-bar__title {
      color: ${theme.COLOR('light')}
    }

    .shp--slice-layout-content-2 {
      text-align: center;
      font-weight: 200;
    }

    .shp--slice-layout-content-with-image {
      .shp--content-node {
        color: ${theme.COLOR('inherit')};
      }
      li::before {
        color: ${theme.COLOR('inherit')};
      }
    }

    #customers .shp--button {
      color: ${theme.COLOR('light')};
      ${theme.FILL('light')};
      border: 2px dotted ${theme.COLOR('light')};
      &:hover {
        color: ${theme.COLOR('#FCB10F')};
        ${theme.FILL('#FCB10F')};
        border: 2px dotted ${theme.COLOR('#FCB10F')};
      }
    }

  `,
  tablet: css`
    .shp--slice-intro {
      font-size: 2em;
      text-align: left;
      line-height: 1.25em;
    }
  `,
  desktop: css`
    .shp--slice-section-color-block {
      min-height: 600px;
    }

    .page-home .shp--page-layout-standard__banner-titles div div {
      gap: 1em;
    }

    .shp--page-footer-layout__layout {
      align-items: flex-start;
    }
    
  `,
});